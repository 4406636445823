
  import { Component } from 'vue-property-decorator'
  import Cell from './cell'
  import { PersonAddress } from '@/entities/persons'

@Component
  export default class AddressCell extends Cell {
  declare item: PersonAddress

  get icon () {
    const { item } = this
    if (!item) return ''

    return item?.icon
  }

  get description () {
    const { item } = this
    if (!item) return ''
    return item?.type?.description || ''
  }
  }
